/* Arama konteyneri */
.search-container {
  z-index: 15; /* Diğer elemanların üstünde görünmesi için */
}

/* Arama kutusu */
.search-input {
  display: flex; /* Flex container yap */
  width: 100%;
  border: 2px solid #fff; /* Kenarlık rengini beyaza çevir */
  border-radius: 20px; /* Köşeleri yuvarlak yap */
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.5); /* Beyaz gölge efekti */
  font-size: 1.2rem; /* Yazı boyutu */
  color: #fff; /* Yazı rengini beyaz yap */
  background-color: rgba(0, 0, 0, 0.9); /* Arka planı koyu gri yap */
  text-align: center;
}

.search-container {
  height: 45px; /* Varsayılan yüksekliği belirle */
}

.search-input {
  height: 42px;
}

@media (max-width: 768px) {
  .search-container {
    height: 32.5px; /* Varsayılan yüksekliği belirle */
  }

  .search-input {
    height: 32px;
  }
}

@media (max-width: 530px) {
  .search-container {
    height: 32px; /* Varsayılan yüksekliği belirle */
  }

  .search-input {
    height: 32px;
    padding-bottom: 3px;
  }
}

/* Arama kutusu placeholder */
.search-input::placeholder {
  font-style: italic;
  font-size: 1.5rem;
  color: #fff;
  opacity: 1;
}

/* Arama kutusu odaklanma durumu */
.search-input:focus {
  outline: none;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.2); /* Beyaz gölge efekti */
}

/* Otomatik tamamlama sonuçları */
.autocomplete-results {
  width: 80vw; /* Genişlik ayarı, maksimum 600px olacak şekilde */
  max-width: 600px;
  max-height: 200px;
  overflow-y: auto; /* Dikey kaydırma */
  border: 1px solid #fff; /* Kenarlık rengini beyaza çevir */
  border-radius: 0 0 20px 20px; /* Alt köşeleri yuvarlak yap */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Gölge efekti */
  position: absolute;
  top: 100%; /* Arama kutusunun hemen altında yer alacak */
  left: 50%; /* Konteyneri sayfanın tam ortasına yerleştir */
  transform: translateX(-50%); /* Konteyneri yatay olarak ortala */
  background-color: #222; /* Arka plan rengini koyu gri yap */
  z-index: 1001; /* Z-index değerini yükselt */
}

/* Otomatik tamamlama öğeleri */
.autocomplete-item {
  display: block;
  padding: 10px;
  cursor: pointer;
  color: #fff; /* Yazı rengini beyaz yap */
  text-decoration: none;
  background-color: #222; /* Arka plan rengini daha koyu gri yap */
}

/* Otomatik tamamlama öğesi üzerine gelindiğinde */
.autocomplete-item:hover {
  background-color: #fff; /* Arka plan rengini beyaza çevir */
  color: #000; /* Yazı rengini siyaha çevir */
}

/* Responsive tasarım ayarları */
@media (max-width: 768px) {
  .search-input,
  .autocomplete-results {
    font-size: 1rem; /* Yazı boyutu */
  }

  .search-input::placeholder {
    font-size: 1.2rem; /* Mobil cihazlarda placeholder yazı boyutunu ayarla */
  }
}

/* Responsive tasarım ayarları */
@media (max-width: 530px) {
  .search-input,
  .autocomplete-results {
    font-size: 1rem; /* Yazı boyutu */
  }

  .search-input::placeholder {
    font-size: 1rem; /* Mobil cihazlarda placeholder yazı boyutunu ayarla */
  }
}
