/* Style for the footer */
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  background-color: #000000;
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.2);
  width: 910px;
  margin: auto;
  border-radius: 15px;
  background-color: #000000; /* Arka plan rengini siyah yap */
  color: #ffffff !important; /* Yazı rengini beyaz yap */
  border-top: 2px solid #ffffff; /* Kutunun kenar çizgisi */
  border-bottom: 2px solid #ffffff; /* Kutunun kenar çizgisi */
  width: 100vw;
  max-width: 910px; /* Maksimum genişlik sınırlaması */
  margin-left: auto; /* Sol tarafta otomatik boşluk */
  margin-right: auto; /* Sağ tarafta otomatik boşluk */
  padding-top: 15px;

  bottom: 45px; /* En altta */
  left: 0; /* Sol taraftan başlat */
  right: 0; /* Sağ taraftan başlat */
  z-index: 1500;
}

@media (max-width: 530px) {
  .footer {
    font-size: 14px; /* Üst menü için font boyutu */
  }
}

footer a:any-link {
  color: #fff;
}

/* Style for the container inside the footer */
.containerFooter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
  margin: 0 auto; /* Center the container horizontally */
}

.contactFooter {
  padding-right: 30px;
}

.contactFooter p {
  margin-bottom: 10px;
  font-size: larger;
}

.legalContainer p {
  margin-bottom: 10px;
  font-size: larger;
}

.legalContainer {
  width: 60%;
  margin: 0 auto; /* Center the container horizontally */
}
